import React, { FC, InputHTMLAttributes, forwardRef, Ref } from 'react';

type Props = InputHTMLAttributes<HTMLInputElement> & {
	ref?: Ref<HTMLInputElement>;
};

export const CountedInput: FC<Props> = forwardRef<HTMLInputElement, Props>(({ children, ...attrs }, ref) => {
	return (
		<span className="inp-fix inp-count">
			<input {...attrs} className={`inp-text${attrs.className ? ` ${attrs.className}` : ''}`} ref={ref} />
			{children}
		</span>
	);
});
