import React, { FC, memo } from 'react';

export type Props = {};

export const SideGraphics: FC<Props> = memo(props => {
	const {} = props;

	return (
		<figure className="b-test__item">
			<img data-src="/img/main2.svg" width="466" height="400" alt="Test rychlosti" className="lazyload" />
			<noscript>
				<img src="/img/main2.svg" width="466" height="400" alt="Test rychlosti" />
			</noscript>
		</figure>
	);
});

SideGraphics.displayName = 'SideGraphics';
