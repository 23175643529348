import { navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { FC, memo, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createNewTest } from '../../api/createNewTest';
import { makeArray } from '../../utils/makeArray';
import { matchUrl } from '../../utils/matchUrl';
import { ResultActionTypes } from '../../utils/resultReducer';
import { ResultContext } from '../molecules/ResultProvider';
import { Container } from '../ui/block/Container';
import { CountedInput } from '../ui/form/CountedInput';
import { Form } from '../ui/form/Form';
import { Input } from '../ui/form/Input';
import { Label } from '../ui/form/Label';
import { Message, Types as MessageTypes } from '../ui/form/Message';
import { SideGraphics } from '../ui/form/SideGraphics';
import { Submit } from '../ui/form/Submit';
import { Text } from '../ui/typography/Text';
import Window from '../../img/window.svg';
import loadable from '@loadable/component';

const Paper = loadable(() => import('../layout/Paper'));

export type Props = {};

const AddressForm: FC<Props> = memo(props => {
	const intl = useIntl();
	const { dispatch } = useContext(ResultContext);
	const { register, handleSubmit, errors } = useForm();
	const [messages, setMessages] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const compareUrls = makeArray(4, '');

	const onSubmit = async data => {
		setMessages([]);
		dispatch({ type: ResultActionTypes.CLEAR });
		setSubmitting(true);
		const urls = Object.values(data);
		try {
			const response = await createNewTest(urls);
			navigate(`/r/${response.hash}/`, { state: { form: true } });
		} catch ({ messageId, values }) {
			setMessages([
				{
					type: MessageTypes.ERROR,
					message: intl.formatMessage({ id: messageId }, values),
				},
			]);
			setSubmitting(false);
		}
	};

	return (
		<Paper className="b-paper--form b-test">
			<Container className="b-test__list">
				<SideGraphics />
				<Container className="b-test__item">
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Container>
							<Text>
								<Label for="url">
									<strong>
										<FormattedMessage id="address.urlLabel" />
									</strong>
								</Label>
								<Input
									ref={register({
										required: intl.formatMessage({ id: 'address.urlEmptyMessage' }),
										pattern: matchUrl(intl.formatMessage({ id: 'address.urlInvalidMessage' })),
									})}
									id="url"
									name="url"
									wrapClassName="inp-icon"
									placeholder={intl.formatMessage({ id: 'address.urlPlaceholder' })}
									autoFocus
									required
								>
									<Window />
									{errors.url && <Message type={MessageTypes.ERROR}>{errors.url.message}</Message>}
								</Input>
							</Text>
						</Container>
						<Container>
							<Text className="inp-count-list">
								{compareUrls.map((compareUrl, index) => (
									<React.Fragment key={index}>
										<Label for={`compareUrl${index}`} className={index > 0 && 'u-vhide'}>
											<FormattedMessage id={`address.compareUrlsLabel${index}`} />
										</Label>
										<CountedInput
											ref={register({
												pattern: matchUrl(
													intl.formatMessage({ id: 'address.urlInvalidMessage' })
												),
											})}
											id={`compareUrl${index}`}
											name={`compareUrl${index}`}
											placeholder="https://"
										>
											{errors[`compareUrl${index}`] && (
												<Message type={MessageTypes.ERROR}>
													{errors[`compareUrl${index}`].message}
												</Message>
											)}
										</CountedInput>
									</React.Fragment>
								))}
							</Text>
						</Container>
						<Submit
							loading={submitting}
							loadingTitle={intl.formatMessage({ id: 'address.submitButtonLoading' })}
						>
							<FormattedMessage id="address.submitButton" />
						</Submit>
					</Form>
					{messages.map((message, key) => (
						<Message key={key} type={message.type}>
							{message.message}
						</Message>
					))}
				</Container>
			</Container>
		</Paper>
	);
});

AddressForm.displayName = 'InputForm';

export default AddressForm;
